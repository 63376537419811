import React, {useEffect, useState} from "react";
import {deleteHunter, getDealHunters} from "../../../pages/deals/view/api";
import {CloseIcon} from "../../UI/Icons";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import EmptyOrShow from "../../EmptyOrShow";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";

const useStyles = makeStyles({
  wrapper: {
    width: 500,

    overflow: "auto"
  },
  hunter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#e0e0e5",
    borderRadius: 4,
    padding: 10,
    marginBottom: 10
  }
});

const HuntersModal = ({ crm_deal_id, setActiveHunter }) => {
  const [hunters, setHunters] = useState([]);
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { addDefaultSnack } = useCustomSnackbar();

  const handleDelete = hunter_id => {
    setLoading(true);
    deleteHunter(crm_deal_id, hunter_id)
      .then(res => {
        setHunters(res.employees);
        setActiveHunter(res.active_hunter);
        addDefaultSnack("Хантер успешно удален");
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDealHunters(crm_deal_id)
      .then(res => {
        setHunters(res.employees);
      })
      .finally(() => setLoading(false));
  }, [crm_deal_id]);

  return (
    <EmptyOrShow loading={loading}>
      <Box className={classes.wrapper}>
        {hunters?.map(el => (
          <Box className={classes.hunter}>
            <p>{el.fio}</p>
            <p>{el.position_name}</p>
            {el.position_code === "SELLER" && el.is_active === false ? (
              <CloseIcon
                className="c-pointer"
                onClick={() => handleDelete(el.employee_id)}
              />
            ) : null}
          </Box>
        ))}
      </Box>
    </EmptyOrShow>
  );
};

export default HuntersModal;
