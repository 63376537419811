import React, {useEffect, useState} from "react";
import {InputLabel, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import styleConstants from "../../../constants/styleConstants";
import {RiArrowDownSLine} from "react-icons/ri";
import {isEmpty} from "components/helpers";

const useStyles = makeStyles({
  select: {
    "& .MuiFormControl-root": {
      width: "100%"
    },
    "& .MuiInputBase-input": {
      cursor: "pointer"
    }
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    color: styleConstants.darkGray,
    marginBottom: 3
  },
  icon: {
    color: styleConstants.primaryColor
  }
});

export default function AutoSuggest({
  label = "",
  name = "",
  selected = "",
  onChange,
  list = [],
  showKey = "name",
  valueKey = "id",
  placeholder,
  disabled,
  className = "",
  error = "",
  noOptionsText = "Ничего не найдено",
  disableClearable = false,
  isMultiple = false
}) {
  const [value, setValue] = React.useState("");
  const [multipleValue, setMultipleValue] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    if (isMultiple) {
      let sel = list?.filter(i =>
        selected?.length ? selected?.some(e => i[valueKey] === e) : false
      );
      if (sel) {
        setMultipleValue(sel);
      } else {
        setMultipleValue([]);
      }
    } else {
      let sel = list?.filter(i => i[valueKey] === selected)[0];
      if (sel) {
        setValue(sel);
      } else {
        setValue(null);
      }
    }
  }, [selected, list, isMultiple, valueKey]);

  function handleChange(event, newValue, reason) {
    let e = { target: { name: name, value: "" } };
    if (isMultiple) {
      e.target.value = newValue?.map(el => el[valueKey]) || null;
      onChange(e);
      setMultipleValue(newValue);
    } else {
      if (newValue || newValue === null) {
        if (!isEmpty(newValue)) e.target.value = newValue[valueKey];
        else e.target.value = newValue;
        setValue(newValue);
      }
      onChange(e, newValue, reason);
    }
  }

  const OptionR = item => {
    return (
      <span className={classes.option} value={item[valueKey]}>
        {item[showKey]}
      </span>
    );
  };

  return (
    <div className={className}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Autocomplete
        multiple={isMultiple}
        disableClearable={disableClearable}
        id={name}
        name={name}
        options={list}
        getOptionLabel={option => option[showKey] || ""}
        value={isMultiple ? (multipleValue ? multipleValue : []) : value}
        onChange={handleChange}
        className={classes.select}
        popupIcon={<RiArrowDownSLine className={classes.icon} />}
        onInputChange={(event, newInputValue) => {
          if (!newInputValue) handleChange(event);
        }}
        noOptionsText={noOptionsText}
        renderInput={params => (
          <TextField
            name={name}
            {...params}
            variant="standard"
            placeholder={placeholder}
            helperText={error}
            error={Boolean(error)}
          />
        )}
        renderOption={OptionR}
        disabled={disabled}
      />
    </div>
  );
}
