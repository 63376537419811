import React, { useState } from "react";
import instance from "../api";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { Button } from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import { makeStyles } from "@material-ui/core/styles";
import LocalPrintshopOutlinedIcon from "@material-ui/icons/LocalPrintshopOutlined";

const DownloadExcel = ({ url, params = {}, title }) => {
  const [downloading, setDownloading] = useState(false);
  const classes = useStyles();

  const downloadFile = () => {
    setDownloading(true);
    instance(true)
      .post(url, params, {
        responseType: "arraybuffer"
      })
      .then((res = {}) => {
        const blob = new Blob([res.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveAs(blob, `${title}- ${format(new Date(), "dd.MM.YYY")}.xlsx`);
        setDownloading(false);
      })
      .catch(() => {
        setDownloading(false);
      });
  };

  return (
    <Button
      className={classes.button}
      disabled={downloading}
      onClick={downloadFile}
    >
      {downloading ? (
        <LoopIcon className={classes.loop} />
      ) : (
        <LocalPrintshopOutlinedIcon />
      )}
    </Button>
  );
};

export default DownloadExcel;

const useStyles = makeStyles({
  button: {
    backgroundColor: "#6191EF",
    height: 30,
    color: "#ffffff",
    borderRadius: 4,

    "&:hover": {
      backgroundColor: "#6191EF"
    }
  },
  loop: {
    animation: `$spin 2000ms infinite linear`,
    color: "#fff"
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0)"
    },
    "100%": {
      transform: "rotate(360deg)"
    }
  }
});
