import {getHunters, recount} from "./view/api";
import {setHuntersList} from "../../services/redux/actions/app-actions";

export const getHuntersData =  (sale_department_id) => async  (dispatch, getState) => {
  if(!sale_department_id) return
  const {huntersList} = getState().appState
  if(huntersList.id === sale_department_id && !!huntersList.list?.length) return
  try {
    const hunters = await getHunters(sale_department_id)
    dispatch(setHuntersList({list: hunters || [], id: sale_department_id }))
  } catch (e) {
    dispatch(setHuntersList({list: [], id: sale_department_id }))
  }
}

export const recountClientChoice = async (crm_deal_id) => {
  try {
    return await recount(crm_deal_id)
  } catch (e) {
  }
}