import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import styleConstants from "../../../constants/styleConstants";
import { ccTaskReport } from "../api";
import Layout from "../../../components/layout";
import { getDateCalendar } from "../../../utils";
import Filters from "./Filters";
import EmptyOrShow from "../../../components/EmptyOrShow";
import TableRowComp from "./TableRowComp";

const headTitle = [
  "Номер задачи",
  "Номер сделки",
  "Тип задачи",
  "Вопросы/Ответы",
  "Менеджер КЦ",
  "Дата создания задачи",
  "Дедлайн задачи",
  "Дата завершения задачи",
];

const CcTask = () => {
  const classes = useTasksStyles();
  const [taskArr, setTaskArr] = useState([]);
  const [filters, setFilters] = useState({
    manager_id: null,
    crm_task_type_id: null,
    date_begin: getDateCalendar(1),
    date_end: getDateCalendar(),
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    ccTaskReport(filters)
      .then((res) => {
        setTaskArr(res);
      })
      .catch(() => {
        setTaskArr([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filters]);

  return (
    <Layout title="Задачи КЦ">
      <Box sx={{ padding: "45px 20px 20px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            flexWrap: "wrap",
            gap: "20px",
            padding: "20px 0",
          }}
        >
          <Filters state={filters} setState={setFilters} />
        </Box>
        <EmptyOrShow loading={loading}>
          <TableContainer className={classes.table_container}>
            <Table
              size="small"
              stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead className={classes.table_head}>
                <TableRow>
                  {headTitle.map((el) => {
                    return <TableCell key={el}>{el}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.table_body}>
                {taskArr?.map((el) => {
                  return <TableRowComp key={el.crm_deal_task_id} {...el} />;
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </EmptyOrShow>
      </Box>
    </Layout>
  );
};

export default CcTask;

export const useTasksStyles = makeStyles({
  table_container: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "4px",
    height: "calc(100vh - 160px)",

    "&::-webkit-scrollbar": {
      width: 8,
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius,
    },
  },
  table_head: {
    "& th": {
      fontSize: "12px",
      lineHeight: "17px",
      fontWeight: 600,
      color: "#111",
      backgroundColor: "#fff",
      borderBottom: "1px solid #ccc !important",
    },
  },
  table_body: {
    "& tr": {
      transition: "0.4s",
      "&:hover": {
        background: "#d3d3d36e",
      },
    },
    "& td": {
      borderTop: "none !important",
      borderBottom: "1px solid #ccc !important",
      borderLeft: "none",
    },
    "& td span": {
      whiteSpace: "nowrap",
    },
  },
  table_footer: {
    "& td": {
      fontSize: 14,
      fontWeight: "bold",
      color: "black",
      backgroundColor: "#fff",
      borderTop: "1px solid #f0f2f6 !important",
    },
    "& td span": {
      whiteSpace: "nowrap",
    },
  },
  questionWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    padding: "10px 0",
    "& > p": {
      margin: 0,
    },
    "& + &": {
      borderTop: "1px solid #ccc",
    },
  },
  question: {
    fontWeight: 600,
    fontSize: 13,
  },
  questionAnswer: {
    fontSize: 13,
  },
  questionComment: {
    fontSize: 12,
    color: "#4d4d4d",
    maxWidth: 300,
  },
});
