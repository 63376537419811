import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setActiveHunter, setShowModal, setStatusInfo, setTasks} from "../../../services/redux/actions/app-actions";

import {makeStyles} from "@material-ui/core/styles";
import globalUseStyles from "../../../components/globalStyles";
import {Box} from "@material-ui/core";

import {changeDealStatus, changeHunter, deleteHunter, getDealHunters, getDealStatuses} from "./api";
import {MODALS} from "../../../constants";
import InfoTxt from "../../../components/UI/InfoTxt";
import CustomButton from "../../../components/UI/Button";
import SelectPopover from "../../../components/Form/SelectPopover";
import CheckboxR from "../../../components/Form/CheckboxR";
import AutoSuggest from "../../../components/UI/AutoSuggest";
import styleConstants from "../../../constants/styleConstants";
import clsx from "clsx";
import EmptyOrShow from "../../../components/EmptyOrShow";
import cookiesService from "../../../services/cookiesService";
import {useCustomSnackbar} from "../../../utils/hooks/useCustomSnackbar";
import {getHuntersData} from "../services"

const useStyles = makeStyles(theme => ({
  dealStatus: {
    width: "100%",
    height: "460px !important",
    background: "#fff",
    border: "1px solid #e2e3ed",
    borderRadius: 4,
    padding: 20,
    gridArea: "1 / 1 / 2 / 4",
    overflow: "auto",
    scrollbarColor: "#6191EF #fff",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: 7
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: styleConstants.primaryColor,
      borderRadius: styleConstants.borderRadius
    },

    [theme.breakpoints.down(850)]: {
      gridArea: "auto"
    }
  },
  dealStatus_head: {
    display: "flex",
    flexWrap: "wrap",
    rowGap: 10
  },
  hunter: {
    width: 250
  },
  hunter_count: {
    fontWeight: 600,
    color: "#fff",
    background: "#6191EF",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    margin: "15px 0 0 5px",
    cursor: "pointer"
  },
  btns: {
    display: "flex",
    gap: 10,
    marginLeft: "auto",

    "& .outlined-btn": {
      backgroundColor: "transparent",
      color: "#6191ef",
      borderColor: "#6191ef",
      borderRadius: 4,
      height: "38px"
    }
  },
  dealStatus_body: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 16,
    marginBottom: 16,
    rowGap: 20
  },
  checklist: {
    width: "72%",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: 10,

    "& label": {
      margin: 0
    }
  },
  date: {
    "& span": {
      fontSize: 14,
      fontWeight: 600,
      color: "#000"
    }
  }
}));

const cookiesServiceInstance = cookiesService.getService();

const DealStatus = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const globalClasses = globalUseStyles();
  const {addDefaultSnack} = useCustomSnackbar();

  const {statusInfo} = useSelector(state => state.appState);
  const {
    crm_deal_id,
    crm_status_name,
    deadline,
    rowversion,
    sale_department_id,
    ...values
  } = useSelector(state => state.appState.dealCreateData);
  const {huntersList} = useSelector(state => state.appState);
  const {activeHunter} = useSelector(state => state.appState);

  const [popoverLoading, setPopoverLoading] = useState(false);
  const [isBtnLock, setIsBtnLock] = useState(false);
  const [loading, setLoading] = useState(false);
  const {position_code} = cookiesServiceInstance.getUserData();

  const getStatusData = () => {
    if (crm_deal_id) {
      getDealStatuses(crm_deal_id).then(res => {
        dispatch(setStatusInfo(res));
      });
    }
  };

  const handleHunterChange = employee_id => {
    changeHunter(crm_deal_id, {employee_id})
      .then(res => {
        dispatch(setActiveHunter(res.active_hunter));
        addDefaultSnack("Хантер успешно назначан");
        getStatusData();
      })
      .catch(() => {
      });
  };

  const handleHunterDelete = () => {
    deleteHunter(crm_deal_id, activeHunter.employee_id)
      .then(res => {
        dispatch(setActiveHunter(res.active_hunter));
        addDefaultSnack("Хантер успешно удален");
      })
      .catch(() => {
      });
  };

  const handleChange = (e, v, reason) => {
    if (crm_deal_id) {
      if (e.target.value) {
        handleHunterChange(e.target.value);
      }
      if (reason === "clear") {
        handleHunterDelete();
      }
    }
  };

  const handleSignContract = () => {
    setIsBtnLock(true);
    changeDealStatus(crm_deal_id, {
      is_contract_signed: !statusInfo?.is_contract_signed
    })
      .then(res => {
        dispatch(setStatusInfo(res));
        dispatch(setTasks(res.tasks));
        addDefaultSnack();
      })
      .catch(() => {
      })
      .finally(() => setIsBtnLock(false));
  };

  const handleAuditorCheck = () => {
    setIsBtnLock(true);
    changeDealStatus(crm_deal_id, {
      is_auditor_checked: !statusInfo?.is_auditor_checked
    })
      .then(res => {
        dispatch(setStatusInfo(res));
        addDefaultSnack();
      })
      .catch(() => {
      })
      .finally(() => setIsBtnLock(false));
  };

  const handleColdLead = () => {
    setIsBtnLock(true);
    changeDealStatus(crm_deal_id, {
      is_cold_lead: true
    })
      .then(res => {
        dispatch(setStatusInfo(res));
        addDefaultSnack()
      })
      .catch(() => {
      })
      .finally(() => setIsBtnLock(false));
  };

  const handleDeniedClick = () => {
    dispatch(
      setShowModal(true, MODALS.deniedContract, {
        title: "Перевод в отказано",
        crm_deal_id
      })
    );
  };

  const handleStatusClick = item => {
    if (item.is_transferable) {
      setPopoverLoading(true);
      changeDealStatus(crm_deal_id, {crm_status_id: item.crm_status_id})
        .then(res => {
          dispatch(setStatusInfo(res));
          addDefaultSnack();
          window.location.reload();
        })
        .catch(() => {
        })
        .finally(() => {
          setPopoverLoading(false);
        });
    }
  };

  const handleClientInterested = () => {
    if (!statusInfo?.is_client_interested) {
      setIsBtnLock(true);

      changeDealStatus(crm_deal_id, {
        is_client_interested: true
      })
        .then(res => {
          dispatch(setStatusInfo(res));
          addDefaultSnack();
        })
        .catch(() => {
        })
        .finally(() => {
          setIsBtnLock(false);
        });
    }
  };

  useEffect(() => {
    dispatch(getHuntersData(sale_department_id))
  }, [sale_department_id, dispatch]);

  useEffect(() => {
    if (crm_deal_id) {
      setLoading(true);
      Promise.all([getDealStatuses(crm_deal_id), getDealHunters(crm_deal_id)])
        .then(([status, hunters]) => {
          dispatch(setStatusInfo(status));
          dispatch(setActiveHunter(hunters.active_hunter));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [crm_deal_id, dispatch]);

  return (
    <Box
      className={clsx(classes.dealStatus, {
        [globalClasses.disable_block]:
        !crm_deal_id || position_code === "SALE_ARCHIVE"
      })}
    >
      <EmptyOrShow loading={!crm_deal_id ? false : loading}>
        <h4 className={globalClasses.deals_main_title}>Статус сделки</h4>
        <Box className={classes.dealStatus_head}>
          <Box className={classes.hunter}>
            <AutoSuggest
              label="Менеджер"
              name={"employee_id"}
              list={huntersList?.list}
              showKey="fio"
              valueKey="employee_id"
              placeholder="Имя хантера"
              onChange={handleChange}
              selected={activeHunter?.employee_id}
            />
          </Box>
          {activeHunter?.cnt > 0 ? (
            <Box
              title="Количество хантеров"
              className={classes.hunter_count}
              onClick={() =>
                dispatch(
                  setShowModal(true, MODALS.huntersModal, {
                    title: "Удаление хантеров",
                    crm_deal_id,
                    setActiveHunter
                  })
                )
              }
            >
              <span>{activeHunter?.cnt}</span>
            </Box>
          ) : null}
          <Box mr={3} ml={3}>
            <InfoTxt label="Заработок со сделки" value=""/>
          </Box>
          <Box className={classes.btns}>
            <Box sx={{display: "flex", flexDirection: "column", gap: "10px"}}>
              {statusInfo?.is_denied === null ||
              typeof statusInfo?.is_denied === "undefined" ? null : (
                <CustomButton
                  btnText="В отказано"
                  variant="outlined"
                  txtColor="#6191EF"
                  className="outlined-btn"
                  handleClick={handleDeniedClick}
                />
              )}
              {statusInfo?.is_cold_lead ? (
                <CustomButton
                  btnText={
                    isBtnLock ? "Пожалуйста, подождите" : "В холодный лид"
                  }
                  variant="outlined"
                  txtColor="#6191EF"
                  className="outlined-btn"
                  handleClick={handleColdLead}
                />
              ) : null}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={210}
              sx={{gap: 10}}
            >
              {statusInfo?.is_auditor_checked === null ||
              typeof statusInfo?.is_auditor_checked === "undefined" ? null : (
                <CustomButton
                  btnText={
                    isBtnLock ? "Пожалуйста, подождите" : "Проверено аудитором"
                  }
                  style={{
                    backgroundColor: statusInfo?.is_auditor_checked
                      ? "#77BF6C"
                      : "#9194A9"
                  }}
                  handleClick={handleAuditorCheck}
                />
              )}
              {statusInfo?.is_contract_signed === null ||
              typeof statusInfo?.is_contract_signed === "undefined" ? null : (
                <CustomButton
                  btnText={
                    isBtnLock ? "Пожалуйста, подождите" : "Договор подписан"
                  }
                  handleClick={handleSignContract}
                  style={{
                    backgroundColor: statusInfo?.is_contract_signed
                      ? "#77BF6C"
                      : "#9194A9"
                  }}
                />
              )}
              {statusInfo?.is_client_interested !== null ? (
                <CustomButton
                  btnText={
                    isBtnLock ? "Пожалуйста, подождите" : "Клиент заинтересован"
                  }
                  handleClick={handleClientInterested}
                  disabled={isBtnLock}
                  style={{
                    backgroundColor: statusInfo?.is_client_interested
                      ? "#77BF6C"
                      : "#9194A9"
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
        <Box className={classes.dealStatus_body}>
          <Box
            sx={{
              zIndex:
                position_code === "CALL_CENTER_HEAD" &&
                values.crm_status_id === 7
                  ? 100
                  : 0
            }}
          >
            {statusInfo?.statuses ? (
              <Box display="flex" alignItems="center">
                <span>Статус:</span>
                <SelectPopover
                  name="crm_status_id"
                  value={values.crm_status_id}
                  onClick={handleStatusClick}
                  list={statusInfo?.statuses || []}
                  valueKey="crm_status_id"
                  showKey="crm_status_name"
                  loading={popoverLoading}
                />
              </Box>
            ) : null}
          </Box>
          <Box className={classes.checklist}>
            {statusInfo?.checklists?.map(el => {
              return (
                <Box key={el.crm_deal_checklist_id}>
                  <CheckboxR
                    allowHint
                    name={el.crm_checklist_name}
                    disabled
                    label={el.crm_checklist_name}
                    value={el.is_complete}
                    colorType={el.is_complete ? "purple" : "grey"}
                    title={el.comments}
                  />
                </Box>
              );
            })}
          </Box>
        </Box>
        {crm_status_name ? (
          <Box className={classes.date}>
            Дата создания сделки: <span>{rowversion}</span>
          </Box>
        ) : null}
      </EmptyOrShow>
    </Box>
  );
};

export default DealStatus;
